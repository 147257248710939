import React, { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import Login from 'pages/Login'
import AppLayout from 'containers/AppLayout'
import { withGlobalLoading } from 'components/commons/GlobalLoading'

import { checkIsLoggedIn } from 'utils/common'

import routes from './routes'

import 'react-toastify/dist/ReactToastify.css'
import 'react-quill/dist/quill.snow.css'
import './App.scss'

function App() {
  const history = useHistory()

  useEffect(() => {
    if (!checkIsLoggedIn()) {
      history.replace(routes.login())
    }
  }, [])

  console.log('dad5460')

  return (
    <Switch>
      <Route exact path={routes.login()} component={Login} />
      <Route path={routes.app()} component={AppLayout} />
    </Switch>
  )
}

export default withGlobalLoading(App)
