import React, { memo, useMemo, useCallback } from 'react'
import classnames from 'classnames'
import { Layout, Dropdown, Menu, Avatar, Grid } from 'antd'
import { useSelector } from 'react-redux'
import { logout } from 'services/auth'
import timesheetLogo from '../../../assets/images/timesheet_icon.png'
import './styles.scss'

const { Header } = Layout

const MainHeader = () => {
  const { userInfo } = useSelector(state => state.general)
  const screens = Grid.useBreakpoint()
  const handleIconClick = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Header
      className={classnames('w-full z-1000 p-0')}
      style={{ background: screens.md ? 'transparent' : '#d0daf6' }}
    >
      <div className="flex h-full items-center justify-between text-white">
        {screens.md && (
          <div
            className={classnames(
              'h-full flex items-center cursor-pointer pl-8',
              {
                'pl-24 pt-4 bg-white w-1/2': screens.md,
                'pl-32': screens.lg,
              }
            )}
          >
            <img
              src={timesheetLogo}
              alt=""
              width="50"
              onClick={handleIconClick}
            />
          </div>
        )}
        <div
          className={classnames(
            'flex h-full items-center justify-end text-white pr-8 navbar-header',
            {
              'w-full': !screens.md,
              'pr-24 bg-primary w-1/2': screens.md,
              'pr-32 pt-4 w-1/2': screens.lg,
            }
          )}
        >
          <div className="font-bold mr-4">{`${userInfo.firstName} ${userInfo.surName}`}</div>
          <div className="bold mr-4">|</div>
          <div className="mr-4">
            <a
              className="hover:text-white"
              target="_blank"
              rel="noreferrer noopener"
              href="https://connetone.freshdesk.com/support/solutions/folders/48000673969"
            >
              Tutorial
            </a>
          </div>
          <div className="bold mr-4">|</div>
          <div className="mr-4 cursor-pointer" onClick={logout}>
            Sign out
          </div>
          {/* <Dropdown overlay={userMenu} trigger={['click']}>
            <Avatar src={userInfo.image} className="cursor-pointer text-primary bg-white font-bold">
              {userInfo.firstName ? userInfo.firstName[0] : ''}
            </Avatar>
          </Dropdown> */}
          <Avatar
            src={userInfo.image}
            className="text-primary bg-white font-bold"
          >
            {userInfo.firstName ? userInfo.firstName[0] : ''}
          </Avatar>
        </div>
      </div>
    </Header>
  )
}

export default memo(MainHeader)
