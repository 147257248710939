export const checkHKPhone = (rule, phoneValue) => {
  if (phoneValue && phoneValue.slice(0, 4) !== '+852') {
    return Promise.reject('Mobile Phone should start with region code (ex: +852)!')
  }
  if (phoneValue && /[^0-9+]/g.test(phoneValue)) {
    return Promise.reject('Mobile Phone must be number!')
  }
  if (phoneValue && phoneValue.length !== 12) {
    return Promise.reject('Mobile Phone must have 8 digits!')
  }
  return Promise.resolve()
}
