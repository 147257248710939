import React, { memo } from 'react'
import { Layout, Menu } from 'antd'
import {
  BarChartOutlined,
  UserOutlined,
  TeamOutlined,
  ProjectOutlined,
  CoffeeOutlined,
  SnippetsOutlined
} from '@ant-design/icons'
import { NavLink } from 'react-router-dom'

import routes from 'routes'
import YKKLogo from 'assets/images/ykk_logo.png'

import './styles.scss'

const { Sider } = Layout
const { Item } = Menu

const SiderItems = [
  {
    title: 'Dashboard',
    icon: <BarChartOutlined />,
    route: routes.dashboard(),
    exact: true
  },
  // {
  //   title: 'Admins',
  //   icon: <UserOutlined />,
  //   route: routes.admins(),
  //   exact: true
  // }
]

const SiderMenu = () => {
  const isActive = (match) => {
    if (!match) {
      return false
    }

    return match.url
  }

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
    >
      <div className="flex justify-center p-4">
        <img src={YKKLogo} alt="YKKLogo" width="50" height="50" />
      </div>
      <Menu theme="dark">
        {SiderItems.map((item, index) => (
          <Item key={index}>
            <NavLink
              to={item.route}
              isActive={isActive}
              activeClassName="ant-menu-item-selected"
            >
              {item.icon}
              <span className="nav-text">{item.title}</span>
            </NavLink>
          </Item>
        ))}
      </Menu>
    </Sider>
  )
}

export default memo(SiderMenu)
