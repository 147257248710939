import ACTION_TYPES from './const'

// login with google
export const loginWithGoogle = (accessToken) => ({
  type: ACTION_TYPES.LOGIN_WITH_GOOGLE,
  payload: accessToken
})

export const loginWithGoogleSuccess = (authData) => ({
  type: ACTION_TYPES.LOGIN_WITH_GOOGLE_SUCCESS,
  payload: authData,
})

export const loginWithGoogleFailed = () => ({
  type: ACTION_TYPES.LOGIN_WITH_GOOGLE_FAILED,
})

// login with phone and password
export const login = (credential) => ({
  type: ACTION_TYPES.LOGIN,
  payload: credential
})

export const loginSuccess = (authData) => ({
  type: ACTION_TYPES.LOGIN_SUCCESS,
  payload: authData,
})

export const loginFailed = () => ({
  type: ACTION_TYPES.LOGIN_FAILED,
})
