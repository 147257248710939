import ACTION_TYPES from './const'

const initialState = {
  authData: {},
  loading: false,
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
    case ACTION_TYPES.LOGIN_WITH_GOOGLE:
      return { ...state, loading: true }
    case ACTION_TYPES.LOGIN_SUCCESS:
    case ACTION_TYPES.LOGIN_WITH_GOOGLE_SUCCESS:
      return { ...state, authData: action.payload, loading: false }
    case ACTION_TYPES.LOGIN_FAILED:
    case ACTION_TYPES.LOGIN_WITH_GOOGLE_FAILED:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default authReducer
