import {
  put, all, call, takeLatest,
} from 'redux-saga/effects'
import { isObject, isEmpty } from 'lodash'
import generalService from 'services/general'

import toastMessage from 'utils/toast'

import * as actions from './actions'

import ACTION_TYPES from './const'
import { isAdmin, isSuperAdmin } from 'utils/common'
import { USER_ROLES_MAP, USER_TYPE, CONFIG } from 'constants/common'

function* getGeneralInfo() {
  try {
    const resUser = yield call(generalService.getUserData)
    const resConfig = yield call(generalService.getConfig)
    const config = isObject(resConfig) && !isEmpty(resConfig) ? resConfig : CONFIG

    const userInfo = {
      ...resUser,
      workingDays: resUser.jobType === USER_TYPE.Site ? config.siteWorkerWorkingDays : config.officerWorkingDays,
      dailyWorkingHours: config.dailyWorkingHours,
      role: USER_ROLES_MAP[resUser.permissions],
    }
    yield put(actions.getGeneralInfoSuccess({ userInfo, config }))
  } catch (error) {
    yield put(actions.getGeneralInfoFailed())
    toastMessage.error(error.message)
  }
}

function* watchGetGeneralInfo() {
  yield takeLatest(ACTION_TYPES.GET_GENERAL_INFO, getGeneralInfo)
}

export default function* globalSagas() {
  yield all([watchGetGeneralInfo()])
}
