import { map, fromPairs } from 'lodash'
import { getRequest } from './base'

export const getUserData = async () => {
  return getRequest('/users/me')
}

export const getConfig = async () => {
  const res = await getRequest('/parameters')
  return res
}

export default {
  getUserData,
  getConfig
}
