import { put, all, call, takeLatest } from 'redux-saga/effects'

import authService from 'services/auth'

import toastMessage from 'utils/toast'
import { setLocalStorage } from 'utils/storage'

import * as actions from './actions'

import ACTION_TYPES from './const'
import { setAccessToken } from 'utils/common'

function* loginWithGoogle(action) {
  try {
    const res = yield call(authService.loginWithGoogle, action.payload)
    setAccessToken(res.token)
    yield put(actions.loginWithGoogleSuccess(res))
  } catch (error) {
    yield put(actions.loginWithGoogleFailed())
    toastMessage.error(error.response.data.message)
  }
}

function* login(action) {
  try {
    const res = yield call(authService.login, action.payload)
    setAccessToken(res.token)
    yield put(actions.loginSuccess(res))
  } catch (error) {
    yield put(actions.loginFailed())
    toastMessage.error(error.response.data.message)
  }
}

function* watchLoginWithGoogle() {
  yield takeLatest(ACTION_TYPES.LOGIN_WITH_GOOGLE, loginWithGoogle)
}

function* watchLogin() {
  yield takeLatest(ACTION_TYPES.LOGIN, login)
}

export default function* globalSagas() {
  yield all([watchLoginWithGoogle(), watchLogin()])
}
