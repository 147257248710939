const ACTION_TYPES = {
  LOGIN_WITH_GOOGLE: 'general/LOGIN_WITH_GOOGLE',
  LOGIN_WITH_GOOGLE_SUCCESS: 'general/LOGIN_WITH_GOOGLE_SUCCESS',
  LOGIN_WITH_GOOGLE_FAILED: 'general/LOGIN_WITH_GOOGLE_FAILED',
  LOGIN: 'general/LOGIN',
  LOGIN_SUCCESS: 'general/LOGIN_SUCCESS',
  LOGIN_FAILED: 'general/LOGIN_FAILED',
}

export default ACTION_TYPES
