import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import store from './states/store'
import './styles/index.css'

const WrappedApp = () => {
  return (
    <Provider store={store}>
      <ToastContainer autoClose={3000} hideProgressBar={true} />
      <Router>
        <App />
      </Router>
    </Provider>
  )
}

ReactDOM.render(<WrappedApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
