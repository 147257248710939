import React, { memo, useCallback, useEffect } from 'react'
import GoogleLogin from 'react-google-login'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Form, Input, Row, Col } from 'antd'
import {
  useGlobalLoading,
  LoadingTrigger,
} from 'components/commons/GlobalLoading'
import { loginWithGoogle, login } from 'states/auth/actions'
import { checkHKPhone } from 'utils/form'
import routes from 'routes'
import { GCP_CLIENT_ID, VALIDATE_MESSAGES, REGION_CODE } from 'constants/common'
import Logo from 'assets/images/ykk_logo.png'
import Google from 'assets/images/Google.png'
import wcircles from 'assets/images/Workcircle_Logo_white.png'

const Login = () => {
  const { startGlobalLoading, endGlobalLoading } = useGlobalLoading()
  const { authData, loading } = useSelector(state => state.auth)
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleSuccess = useCallback(async response => {
    dispatch(loginWithGoogle(response.accessToken))
  }, [])

  const handleFailure = useCallback(response => {
    console.log('response: ', response)
  }, [])

  const handleLogin = useCallback(async () => {
    dispatch(login(form.getFieldsValue()))
  }, [])

  useEffect(() => {
    if (authData.token && authData.token.accessToken) {
      history.push(routes.app())
    }
  }, [authData])

  return (
    <div className="h-screen w-screen">
      {loading && <LoadingTrigger />}
      <div className="flex justify-center items-center flex-col">
        <div className="pt-40">
          <img src={Logo} alt="" />
        </div>
        <div className="pt-8">
          <GoogleLogin
            clientId={GCP_CLIENT_ID}
            buttonText="Sign in with Google"
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            scope="email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
          />
        </div>
        <div className="pt-8">- or -</div>
      </div>

      <Row>
        <Col xs={{ span: 16, offset: 4 }} sm={{ span: 8, offset: 8 }}>
          <div className="pt-8">
            <Form
              layout="vertical"
              form={form}
              name="LoginForm"
              validateMessages={VALIDATE_MESSAGES}
              onFinish={handleLogin}
              initialValues={{ personalPhone: REGION_CODE.HK }}
            >
              <Form.Item
                placeholder="Mobile phone"
                label="Mobile phone"
                name="personalPhone"
                rules={[{ required: true }, { validator: checkHKPhone }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>

              <div className="text-center">
                <Button type="primary" htmlType="submit" shape="round">
                  Login
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Login)
