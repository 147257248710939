export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN_DEV

export const GCP_CLIENT_ID = process.env.REACT_APP_GCP_CLIENT_ID

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'TIMESHEET_ACCESS_TOKEN',
}

export const VALIDATE_MESSAGES = {
  required: '${label} is required!',
  types: {
    email: '${label} is not valid!',
    number: '${label} is not a valid!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

export const GENDER_OPTIONS = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Other', value: 3 },
]

export const REGION_CODE = {
  HK: '+852',
}

export const SORT_VALUE = {
  ascend: 'ASC',
  descend: 'DESC',
}

export const USER_ROLES = {
  SuperAdmin: 'Super Admin',
  Admin: 'Admin',
  User: 'User',
}

export const USER_ROLES_MAP = {
  1: USER_ROLES.User,
}

export const CONFIG = {
  dailyWorkingHours: 8,
  siteWorkerWorkingDays: [1, 2, 3, 4, 5, 6],
  officerWorkingDays: [1, 2, 3, 4, 5, 6],
  calendarColor: {
    fullFill: '#376EFF',
    halfFill: '#FF7828',
    hourFill: '#2C9F30FF',
    noFill: '#000',
    fulFillBackground: '#d2d2ff',
    halfFillBackground: '#ffeac5',
  },
}

export const USER_TYPE = {
  Site: 2,
  Office: 1,
}
